import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 515.000000 505.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,505.000000) scale(0.100000,-0.100000)">


<path d="M2525 4414 c-16 -2 -82 -11 -145 -20 -231 -31 -423 -95 -638 -212
-515 -280 -869 -787 -957 -1373 -73 -484 43 -975 326 -1374 178 -251 461 -491
719 -608 217 -99 375 -143 610 -173 304 -38 648 10 925 128 104 45 244 116
295 151 68 46 187 165 232 231 185 270 149 642 -82 860 -93 87 -164 132 -420
260 -271 135 -298 152 -375 230 -136 139 -167 320 -82 479 86 162 253 238 438
200 182 -37 342 -229 430 -516 36 -120 42 -132 60 -132 19 0 22 82 14 423 l-7
283 -34 -50 c-58 -86 -82 -89 -227 -30 -210 85 -324 103 -465 74 -100 -20
-135 -34 -218 -86 -180 -114 -295 -333 -295 -559 0 -184 62 -327 189 -436 83
-71 147 -110 347 -215 271 -141 379 -229 437 -356 121 -269 45 -515 -209 -679
-96 -61 -266 -122 -421 -150 -48 -8 -161 -18 -252 -21 -253 -9 -427 15 -640
88 -665 227 -1136 812 -1211 1504 -14 134 -7 383 16 515 9 52 36 154 60 226
199 602 674 1042 1295 1199 225 58 597 57 825 -1 817 -207 1376 -918 1375
-1748 -1 -340 -76 -606 -268 -947 -24 -41 -33 -74 -38 -127 -4 -45 -3 -72 3
-72 25 0 150 192 215 330 100 212 142 364 173 625 51 421 -59 872 -302 1239
-297 446 -754 738 -1288 821 -100 15 -353 27 -410 19z"/>
<path d="M1700 3898 c-19 -13 -36 -31 -38 -40 -3 -14 16 -17 170 -20 166 -3
175 -4 230 -31 109 -54 204 -184 243 -337 23 -89 16 -260 -14 -345 -47 -131
-148 -246 -269 -303 l-77 -37 -327 -3 c-311 -4 -328 -5 -328 -22 0 -18 15 -19
263 -22 l262 -3 57 -28 c62 -30 132 -103 206 -214 57 -85 192 -353 281 -558
40 -93 89 -205 109 -248 l35 -77 276 2 c238 3 276 5 276 18 0 9 -18 21 -45 30
-78 26 -115 56 -170 140 -76 115 -85 133 -256 480 -86 173 -173 339 -195 368
-25 32 -61 64 -94 83 -30 17 -57 34 -60 38 -2 4 39 22 92 40 122 40 198 86
268 163 147 163 178 400 80 607 -47 98 -84 143 -168 201 -137 97 -272 129
-567 137 -204 5 -205 5 -240 -19z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
